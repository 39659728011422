import { computed } from 'vue'
import { useIsFormValid, useFormErrors } from 'vee-validate'

export const formHasErrors = computed<boolean>(() => {
	const errors = useFormErrors()
	const isValid = useIsFormValid()

	return Object.keys(errors.value).length > 0 && !isValid.value
})

export const scrollToError = () => {
	const firstError = document.querySelector('.is-danger:first-of-type')?.closest('.field')

	firstError && firstError.scrollIntoView({ behavior: 'smooth', block: 'start' })

	return false
}
