import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bc-welcome-screen" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "column is-centered is-12 is-flex is-align-items-center is-flex-direction-column" }
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SurveyForm = _resolveComponent("SurveyForm")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("img", {
            class: "mt-6 mb-6",
            src: require('@/assets/ld-logo-square.svg')
          }, null, 8, _hoisted_5),
          _createVNode(_component_SurveyForm)
        ])
      ])
    ])
  ], 512)), [
    [_vShow, _ctx.showWelcomeSurvey]
  ])
}