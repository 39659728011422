import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6405f4f5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mt-0 mb-6" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "field" }
const _hoisted_7 = { class: "control is-flex is-justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ld_textarea = _resolveComponent("ld-textarea")!
  const _component_ld_error = _resolveComponent("ld-error")!
  const _component_LdRadioButtonList = _resolveComponent("LdRadioButtonList")!
  const _component_LdCheckboxList = _resolveComponent("LdCheckboxList")!
  const _component_ld_button = _resolveComponent("ld-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t('welcome.title')), 1),
    _createElementVNode("form", {
      class: "form-centered mb-6",
      onSubmit: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)))
    }, [
      _createElementVNode("div", {
        ref: _ctx.refs.aboutUs,
        class: "field"
      }, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('welcome.survey.aboutUs.question')), 1),
        _createVNode(_component_ld_textarea, {
          ".onChange": _ctx.onChangeAboutUs,
          ".onBlur": _ctx.onBlurAboutUs,
          name: _ctx.SURVEY_FORM_FIELDS.aboutUs,
          cols: 30,
          rows: 4,
          placeholder: _ctx.$t('welcome.survey.aboutUs.placeholder'),
          isError: _ctx.aboutUsError
        }, null, 8, [".onChange", ".onBlur", "name", "placeholder", "isError"]),
        _createVNode(_component_ld_error, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.aboutUsError), 1)
          ]),
          _: 1
        })
      ], 512),
      _createElementVNode("div", {
        ref: _ctx.refs.role,
        class: "field"
      }, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('welcome.survey.role.question')), 1),
        _createVNode(_component_LdRadioButtonList, {
          modelValue: _ctx.role,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => ((_ctx.role) = $event)),
            _cache[1] || (_cache[1] = ($event: any) => (_ctx.onValueChange($event, _ctx.SURVEY_FORM_FIELDS.role, 'radio')))
          ],
          items: _ctx.rolesList,
          name: _ctx.SURVEY_FORM_FIELDS.role,
          vertical: "",
          error: _ctx.roleError
        }, null, 8, ["modelValue", "items", "name", "error"])
      ], 512),
      _createElementVNode("div", {
        ref: _ctx.refs.agency,
        class: "field"
      }, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('welcome.survey.agency.question')), 1),
        _createVNode(_component_LdRadioButtonList, {
          modelValue: _ctx.agency,
          "onUpdate:modelValue": [
            _cache[2] || (_cache[2] = ($event: any) => ((_ctx.agency) = $event)),
            _cache[3] || (_cache[3] = ($event: any) => (_ctx.onValueChange($event, _ctx.SURVEY_FORM_FIELDS.agency, 'radio')))
          ],
          items: _ctx.agencyList,
          name: _ctx.SURVEY_FORM_FIELDS.agency,
          error: _ctx.agencyError
        }, null, 8, ["modelValue", "items", "name", "error"])
      ], 512),
      _createElementVNode("div", {
        ref: _ctx.refs.hopes,
        class: "field"
      }, [
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('welcome.survey.hopes.question')), 1),
        _createVNode(_component_LdCheckboxList, {
          modelValue: _ctx.hopes,
          "onUpdate:modelValue": [
            _cache[4] || (_cache[4] = ($event: any) => ((_ctx.hopes) = $event)),
            _cache[5] || (_cache[5] = ($event: any) => (_ctx.onValueChange($event, _ctx.SURVEY_FORM_FIELDS.hopes, 'checkbox')))
          ],
          items: _ctx.hopesList,
          name: _ctx.SURVEY_FORM_FIELDS.hopes,
          vertical: "",
          error: _ctx.hopesError
        }, null, 8, ["modelValue", "items", "name", "error"])
      ], 512),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_ld_button, {
            rounded: "",
            type: "submit",
            blue: "",
            disabled: _ctx.isSubmitDisabled,
            medium: "",
            loading: _ctx.loading
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('welcome.survey.cta')), 1)
            ]),
            _: 1
          }, 8, ["disabled", "loading"])
        ])
      ])
    ], 32)
  ], 64))
}