
import { useField, useForm } from 'vee-validate'
import { defineComponent, watch, computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import * as yup from 'yup'
import { formHasErrors } from '@/services/form'

import LdRadioButtonList from '@/components/LdRadioButtonList/LdRadioButtonList.vue'
import LdCheckboxList from '@/components/LdCheckboxList/LdCheckboxList.vue'
import LdButton from '@/components/LdButton/LdButton.vue'
import { useStore } from 'vuex'
import { Status } from '@/store/state'
import router from '@/router'
import { RouteNames } from '@/types/routes'
import { sendPageViewEvent, sendBehaviorEvent, AppVirtualUrls } from '@/services/segmentTracking'

enum SURVEY_FORM_FIELDS {
	aboutUs = 'aboutUs',
	role = 'role',
	agency = 'agency',
	hopes = 'hopes',
}

interface UserRoles {
	id: string
	label: string
	value: string
}

interface UserHopes {
	id: string
	label: string
	value: string
}

const roles: Array<string> = ['ceo', 'cto', 'brandManager', 'marketingManager', 'analyst', 'other']

export default defineComponent({
	name: 'WelcomeForm',
	components: {
		LdButton,
		LdRadioButtonList,
		LdCheckboxList,
	},
	emits: ['nextPage'],
	setup() {
		const { t } = useI18n()
		const store = useStore()
		const rolesList: Array<UserRoles> = roles.map((role, i) => {
			return {
				id: `role_${i}`,
				value: t(`welcome.survey.role.options.${role}`),
				label: t(`welcome.survey.role.options.${role}`),
			}
		})
		const agencyList = [
			{
				id: 'agency_1',
				label: t('welcome.survey.agency.options.yes'),
				value: true,
			},
			{
				id: 'agency_2',
				label: t('welcome.survey.agency.options.no'),
				value: false,
			},
		]
		const hopesList: Array<UserHopes> = []

		for (let i = 0; i < 10; i++) {
			hopesList.push({
				id: `hopes_${i}`,
				label: t(`welcome.survey.hopes.options[${i}]`),
				value: t(`welcome.survey.hopes.options[${i}]`),
			})
		}

		const schema = yup.object({
			aboutUs: yup.string().required(t('common.required')),
			role: yup.string().required(t('common.required')),
			agency: yup.boolean().required(t('common.required')),
			hopes: yup.array().min(1, t('common.required')).required(t('common.required')),
		})
		const { validate, values, errors, setFieldValue } = useForm({
			validationSchema: schema,
			validateOnMount: false,
		})
		const { value: aboutUs, errorMessage: aboutUsError } = useField(SURVEY_FORM_FIELDS.aboutUs)
		const { value: role, errorMessage: roleError } = useField(SURVEY_FORM_FIELDS.role)
		const { value: agency, errorMessage: agencyError } = useField(SURVEY_FORM_FIELDS.agency)
		const { value: hopes, errorMessage: hopesError } = useField(SURVEY_FORM_FIELDS.hopes)
		const refs: any = {
			aboutUs: ref(null),
			role: ref(null),
			agency: ref(null),
			hopes: ref(null),
		}
		const onChangeAboutUs = (e: any) => {
			setFieldValue(e.target.name, e.target.value)
		}
		const onBlurAboutUs = (e: Event) => {
			const target = e.target as HTMLInputElement

			sendBehaviorEvent({
				locationTitle: t('welcome.title'),
				virtualUrl: AppVirtualUrls.welcomeAbout,
				fieldTitle: t(`welcome.survey.${SURVEY_FORM_FIELDS.aboutUs}.question`),
				actionId: SURVEY_FORM_FIELDS.aboutUs,
				actionValue: target.value,
				actionType: 'text',
			})
		}
		const onSubmit = async (event: Event) => {
			event.preventDefault()

			sendBehaviorEvent({
				locationTitle: t('welcome.title'),
				virtualUrl: AppVirtualUrls.welcomeAbout,
				fieldTitle: '',
				actionId: '',
				actionValue: t('welcome.survey.cta'),
				actionType: 'button',
			})

			const { valid } = await validate()

			if (valid) {
				store.dispatch('registration/survey/updateSurvey', {
					aboutUs: values.aboutUs!,
					role: values.role!,
					agency: values.agency!,
					hopes: values.hopes!,
				})
			} else {
				const errorRefName = Object.keys(errors.value)[0]

				refs[errorRefName].value.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				})
			}
		}
		const isSubmitDisabled = formHasErrors
		const surveyState = computed(() => store.getters['registration/survey/getState'])

		watch(surveyState.value, ({ state }) => {
			if (state === Status.LOADED) router.push({ name: RouteNames.PAYWALL })
		})

		const onValueChange = (
			inputValue: string | boolean | string[],
			elementId: string,
			elementType: string
		) => {
			sendBehaviorEvent({
				locationTitle: t('welcome.title'),
				virtualUrl: AppVirtualUrls.welcomeAbout,
				fieldTitle: t(`welcome.survey.${elementId}.question`),
				actionId: elementId,
				actionValue:
					typeof inputValue === 'boolean' ? (inputValue ? 'yes' : 'no') : inputValue,
				actionType: elementType,
			})
		}

		return {
			SURVEY_FORM_FIELDS,
			onSubmit,
			isSubmitDisabled,
			rolesList,
			agencyList,
			hopesList,
			aboutUs,
			role,
			agency,
			hopes,
			roleError,
			aboutUsError,
			refs,
			agencyError,
			hopesError,
			onChangeAboutUs,
			onBlurAboutUs,
			loading: computed(() => store.getters['registration/survey/isLoading']),
			onValueChange,
		}
	},
	mounted() {
		const { t } = useI18n()

		sendPageViewEvent({
			locationTitle: t('welcome.title'),
			virtualUrl: 'welcome-about',
		})
	},
})
