
import { defineComponent, PropType, provide, ref } from 'vue'
import LdCheckbox from './LdCheckbox/LdCheckbox.vue'

export interface CheckboxItem {
	id: string
	label: string
	value: string
	disabled?: boolean
}

export default defineComponent({
	name: 'LdCheckboxList',
	components: {
		LdCheckbox,
	},
	props: {
		modelValue: {
			type: [Array, Boolean] as PropType<CheckboxItem[] | boolean>,
			default: () => [],
			required: true,
		},
		items: {
			type: Array as PropType<CheckboxItem[]>,
			default: () => [],
		},
		vertical: {
			type: Boolean,
			default: false,
		},
		name: {
			type: String as PropType<string>,
			default: 'radioGroup',
			required: true,
		},
		error: {
			type: [Boolean, String] as PropType<boolean | string>,
			default: null,
		},
	},
	emits: ['update:modelValue'],
	setup(props) {
		provide('name', props.name)
		const listModel = ref(props.modelValue)

		return {
			listModel,
		}
	},
})
