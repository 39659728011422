
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import SurveyForm from './SurveyForm.vue'

export default defineComponent({
	name: 'Home',
	components: {
		SurveyForm,
	},
	setup() {
		const store = useStore()
		// get registration state
		const { value: registrationData } = computed(() => store.getters['registration/getState'])
		const { value: userData } = computed(() => store.getters['user/me/getState'])
		const { value: onboadingEntryData } = computed(
			() => store.getters['onboardingEntry/getState']
		)

		const { hash, name } = userData.data.store

		let showWelcomeSurvey = true
		if (onboadingEntryData?.data?.notificationEmail) {
			store.dispatch('registration/user/updateUserDetails', {
				notificationEmail: onboadingEntryData?.data.notificationEmail,
			})
			store.dispatch('onboardingEntry/remove/removeOnboardingEntry')
		} else if (!registrationData?.data?.notificationEmail) {
			const currentIframeLink = window.location.href

			const onboardingRedirectUrl = `${process.env.VUE_APP_ONBOARDING_HOST}/?source=bigcommerce&storeUrl=store-${hash}&storeName=${name}&iframeRedirectUrl=${currentIframeLink}`
			showWelcomeSurvey = false
			window.location.href = onboardingRedirectUrl
		}
		return {
			showWelcomeSurvey,
		}
	},
})
