
import { computed, defineComponent, PropType, inject } from 'vue'

export default defineComponent({
	name: 'LdCheckbox',
	inheritAttrs: false,
	props: {
		modelValue: {
			type: [Boolean, Array] as PropType<boolean | (string | Boolean)[]>,
			default: false,
			required: true,
		},
		disabled: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		value: {
			type: String as PropType<string>,
			default: '',
		},
		id: {
			type: String as PropType<string>,
			default: '',
		},
		error: {
			type: [Boolean, String] as PropType<boolean | string>,
			default: false,
		},
	},
	emits: ['update:modelValue'],
	setup(props, { emit }) {
		const name = inject('name', props.id)
		const state = computed(() => {
			if (Array.isArray(props.modelValue)) {
				return props.modelValue.indexOf(props.value) > -1
			}

			return !!props.modelValue
		})

		function onChange() {
			let inputValue

			if (Array.isArray(props.modelValue)) {
				inputValue = props.modelValue.slice(0)

				if (state.value) {
					inputValue.splice(inputValue.indexOf(props.value), 1)
				} else {
					inputValue.push(props.value)
				}
			} else {
				inputValue = !state.value
			}

			emit('update:modelValue', inputValue)
		}

		return {
			name,
			state,
			onChange,
		}
	},
})
