
import { defineComponent, PropType, provide } from 'vue'
import LdRadioButton from './LdRadioButton/LdRadioButton.vue'

export interface RadioItem {
	id: string
	label: string
	value: string | boolean
	disabled?: boolean
}

export default defineComponent({
	name: 'LdRadioButtonList',
	components: {
		LdRadioButton,
	},
	props: {
		modelValue: {
			type: [String, Boolean] as PropType<string | boolean>,
			default: '',
			required: true,
		},
		items: {
			type: Array as PropType<Array<RadioItem>>,
			default: () => [],
		},
		vertical: {
			type: Boolean,
			default: false,
		},
		name: {
			type: String as PropType<string>,
			default: 'radioGroup',
			required: true,
		},
		error: {
			type: [Boolean, String] as PropType<boolean | string>,
			default: null,
		},
	},
	emits: ['update:modelValue'],
	setup(props) {
		provide('name', props.name)
	},
})
