import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "help is-danger" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LdCheckbox = _resolveComponent("LdCheckbox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(['control', 'checkbox-list', { 'checkbox-list--vertical': _ctx.vertical }]),
      role: "group"
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createBlock(_component_LdCheckbox, {
          id: item.id,
          key: item.id,
          modelValue: _ctx.listModel,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => ((_ctx.listModel) = $event)),
            _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
          ],
          disabled: item.disabled,
          value: item.value,
          error: _ctx.error
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item.label), 1)
          ]),
          _: 2
        }, 1032, ["id", "modelValue", "disabled", "value", "error"]))
      }), 128))
    ], 2),
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.error), 1)
  ], 64))
}