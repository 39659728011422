import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e7bab606"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "help is-danger" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LdRadioButton = _resolveComponent("LdRadioButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(['radio-list', { 'radio-list--vertical': _ctx.vertical }]),
      role: "radiogroup"
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createBlock(_component_LdRadioButton, {
          id: item.id,
          key: item.id,
          disabled: item.disabled,
          label: item.label,
          value: item.value,
          error: _ctx.error,
          onChecked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
        }, null, 8, ["id", "disabled", "label", "value", "error"]))
      }), 128))
    ], 2),
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.error), 1)
  ], 64))
}