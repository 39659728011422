import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d8f936a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bc-checkbox" }
const _hoisted_2 = ["id", "name", "disabled", "value", "checked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _createElementVNode("input", {
      id: _ctx.id,
      type: "checkbox",
      name: _ctx.name,
      disabled: _ctx.disabled,
      value: _ctx.value,
      class: "mr-2",
      checked: _ctx.state,
      role: "checkbox",
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
    }, null, 40, _hoisted_2),
    _createElementVNode("span", {
      class: _normalizeClass(['checkmark', { 'is-danger': _ctx.error }]),
      "aria-hidden": "true"
    }, null, 2),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}