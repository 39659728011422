
import { defineComponent, PropType, inject } from 'vue'
import { BOOLEAN_MAPPING } from '@/lib/constants'

export default defineComponent({
	name: 'LdRadioButton',
	inheritAttrs: false,
	props: {
		id: {
			type: String as PropType<string>,
			default: '',
		},
		label: {
			type: String as PropType<string>,
			default: '',
		},
		value: {
			type: [String, Boolean] as PropType<string | boolean>,
			default: '',
		},
		disabled: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		vertical: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		error: {
			type: [Boolean, String] as PropType<boolean | string>,
			default: false,
		},
	},
	emits: ['checked'],
	setup(props, { emit }) {
		const name = inject('name')
		const onChange = (event: Event) => {
			const target = event.target as HTMLInputElement
			const value = BOOLEAN_MAPPING[target.value] ?? target.value

			emit('checked', value)
		}

		return {
			name,
			onChange,
		}
	},
})
