import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c0a6114"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "disabled", "value", "name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", {
    class: "bc-radio",
    for: _ctx.id
  }, [
    _createElementVNode("input", {
      id: _ctx.id,
      type: "radio",
      disabled: _ctx.disabled,
      value: _ctx.value,
      class: "mr-2",
      name: _ctx.name,
      role: "radio",
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
    }, null, 40, _hoisted_2),
    _createElementVNode("span", {
      class: _normalizeClass(['checkmark', { 'is-danger': _ctx.error }]),
      "aria-hidden": "true"
    }, null, 2),
    _createTextVNode(" " + _toDisplayString(_ctx.label), 1)
  ], 8, _hoisted_1))
}